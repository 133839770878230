 /* styles.css */

/* IMPORT */
 @import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;400&display=swap");

/* ROOT */
:root {
  --text-colour: #f0f0f0;  
  --primary: #181818;
  --secondary: #1d1d1d;
  --third: #17171a;
  --fourth: #f0f0f3;
  --fifth: #252525;
  --highlight: #e75511;
  --highlight2: #af2900;
  --orange-heading:  #e75511;
  --title:  #575757;
  --logo1: var(--highlight);
  --logo2: var(--highlight);
  --oldlogo: rgb(255, 132, 10);

}

 * {
   box-sizing: border-box;
   /* margin: 0;
   padding: 0; */
 }
 
 body {
   font-family: "Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
     "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
     sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   background-color: var(--text-colour);
   color: var(--text-colour);
    z-index: -70;
  }


/* Scroll Bar */

/* width */
::-webkit-scrollbar {
  width: 10px;
  /* z-index: -5; */
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--text-colour);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--highlight);
}

/*  */
 .container {
   max-width: 90%;
   margin-right: auto;
   margin-left: auto;
   padding: 1rem;
 }
 
 article h1 {
   margin: 1rem 0;
 }

 .brand-name {
  text-decoration: none;
  color: var(--highlight);
  font-size: 1.3rem;
  margin-left: 1rem;
  /* padding: 10px; */
}
.brand-name.logo {
  height: 100%;
}

.brand-name.logo svg {
  /* height: 80% !important; */
  /* height: auto; */
  /* height: 100%;
  width: 100%; */
  /* height: 60px; */
  /* height: 100px; */
  display: inline;
  height: 100%;
}



.navigation-menu {
  margin-left: auto;
}

 .navigation {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  color: var(--text-colour) !important;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
  padding: 10px;
  background-color: var(--fifth);
}
.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
  height: 100%;
}
.navigation-menu .link {
  color: inherit;
  text-decoration: none;
  display: block;
  position: relative;
  font-size: 20px;
  padding: 0.2em 0;
}

.navigation-menu .link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: var(--text-colour);
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

.navigation-menu .link::after {
  opacity: 1;
  transform: scale(0);
  transform-origin: center;
}

.navigation-menu .link:hover::after,
.navigation-menu .link:focus::after{
  transform: scale(1);
}

.navigation-menu li .link.highlight {
  font-weight: bold;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  background-color: var(--secondary);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

.hamburger svg {
  fill: var(--text-colour);
  transition: fill 0.2s ease-in-out;

}


.hamburger:hover svg {
  fill: var(--highlight);
  transition: fill 0.2s ease-in-out;

}

.mobile-logo {
  display: none;
}

.logo-container {
  position: absolute;
  display: flex;
  height: 500px;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}


.desk-hide {
  display: none;
}

@media screen and (max-width: 768px) {

  .page-container {
    padding: 0 !important;
  }

  
  .team-container {
    flex-direction: column;
    align-items: center;
  }

  
  .team-container div {
    width: 100% !important;
  }
  .carousel img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .mbl-hide {
    display: none;
  }

  .mobile-logo {
    display: flex;
  }

  .mbl-padding {
    padding: 5% 10% !important;
  }

  .contact-links {
    font-size: 25px !important;
  }

  .logo-old {
    width: 50% !important; 
  }

  .title-container {
    margin-top: 60vh !important;
  }

  .desk-hide {
    display: block !important;
  }

  .level.secondary .contents {
    padding: 8% 10% !important;
  }
}

#video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  overflow: hidden;
}

/* MAIN CONTENT */

.App {
  min-height: 100vh;
}

.shape.flipped {
  transform: scale(-1, -1) !important;
}

.shape2 {
  fill: var(--secondary);
}


.page-container {
  margin-top: 80px;
  width: 100%;
  min-height: calc(100vh - 124px);
  flex-direction: column;
  display: flex;
  align-content: center;
  text-align: center;
  /* padding: 50px; */
}

.page-container.center {
  justify-content: center;
}

.page-container h1.title {
  font-size: 60px;
  font-style: italic;
  margin: 50px 0;
}

.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  content-visibility: auto;

}

.black {
  font-weight: bold;
}

.firesight {
  position: relative;
  width: 20%; 
}

/* .main */

.title-container {
  transform: translate(0, 15px);
  margin-top: 10vh;
  margin-bottom: 10vh;
  fill: var(--fifth);

}

.title-container svg {
  width: 40%;
  padding: 10px;
  background-color: #1313132d;
}

.textbox {
  background-color: var(--primary);
  margin: 10px;
  padding: 50px 20%;
  box-shadow: 0 2px 2px 2px rgba(12, 12, 9, 0.23);
}

.main.center { 
  justify-self: center;
  align-self: center;
  padding: 50px;
  width: 500px;
  height: 20%;
  transform: translate(0, calc(-50px));
  text-align: center;
}

.level svg {
  transform: translate(0, 10px);
}

.level.secondary .contents {
  background-color: var(--fifth);
  color: var(--text-colour);
  padding: 2% 10%;
}
.level.secondary svg {
  fill: var(--secondary);
}

.level.secondary h1 {
  color: var(--oldlogo);
}

.level .contents {
  width: 100%;
  padding: 5% 10%;
  /* padding-bottom: 200px; */
}

.level.third .contents {
  background-color: var(--third);
  color: var(--text-colour);
}

.level.third svg {
  fill: var(--third);
}


.level.fourth .contents {
  background-color: var(--fourth);
  color: var(--primary);
  padding: 5% 2%;
 
}

.level.fourth h1 { 
  color: var(--orange-heading);
}

.level.fourth .big { 
  font-size: 50px;
}


.level.fourth h2 { 
  color: var(--title)
}

.level.fourth svg {
  fill: var(--fourth);
}

.img-bg-rel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../img/bg1.webp');
  background-position: center;
  background-size: cover;
  filter: brightness(.6);
  z-index: -10;
}

.level.image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; 
  overflow: hidden;
}

/* Partners */

.partners-container {
  display: flex;
  justify-content: center;
  align-items: center; 
}

.partners-container div {
  padding: 20px;
  margin: 20px;
  max-width: 300px;
}

.partners-container img {
  width: 100%; 
  max-width: 200px;
  min-width: 200px;
  transition: 500ms;
  
}

.partners-container img:hover {
  transition: 500ms;
  transform: scale(1.1, 1.1);
}

.partners-container div h1{
  font-size: 40px;
  /* color: var(--primary) !important; */
}

@media screen and (max-width: 1000px) {
  .partners-container {
    flex-direction: column;
  }
  .team-container div {
    padding: 0px !important;
  }

  }

/* Our Team */

.team-container {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  

}

.team-container div {
  padding: 20px;
  width: 40%;
  margin: 20px;
  max-width: 450px;
}

.team-container a {
  margin: 5px;
}

.team-container a svg {
  fill: var(--title) !important;
  width: 20px;
}

.team-container p {
  margin-top: 20px;
  text-align:justify;
  }

.team-container img {
  width: 100%;
  border-radius: 50%;
  padding: 10px;

}

/* Info Grid */

.feature-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  margin: 90px 20px;
}

.feature-card {
  position: relative;
  flex: 1;
  min-width: 350px;
  max-width: 600px;
  background-color: var(--third);
  color: var(--text-colour);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: 0.3s ease-in-out;

}

.feature-card:hover {
  box-shadow: 5px 5px 4px var(--highlight);
}

.feature-card img {
  max-width: 100%;
  height: auto;
}

.feature-card h2 {
  margin-top: 10px;
  margin: 20px 0px;
  color: var(--oldlogo);
}

.feature-card p {
  margin: 0 25px;
  margin-top: 5px;
  margin-bottom: 70px;
}

.feature-card button {
  width: 100%;
  height: 40px;
  cursor: pointer;
  font-size: 15px;
  position: absolute;
  bottom: 0px; 
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border: none;
  background-color: var(--highlight);
}

/* Info Page */

.info-page {
  z-index: 20;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #252525ec;
  backdrop-filter: blur(3px);
  overflow-x: hidden !important;
}

.info-container {
  display: flex;
  min-height: 80vh;
  margin: 5%;
  overflow-y: auto;
  position: relative;

  
}

.info-container div {
  width: 50%;
}

.info-container div h1 {
  margin: 20px 0;
  color: var(--highlight);
}

.info-container div:first-child {
 padding: 20px;
 text-align: center;
 background-color: #17171ab2;
 backdrop-filter: blur(5px);
 min-height: 100%;
}

.info-container .bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;

  object-fit: cover;
  z-index: -1; 
  overflow: hidden;
}

.info-container .info-img {
  position: absolute;

  width: 35%;
  right: 7.5%;
  /* top: 30%; */
}

.info-container .info-img-inline {
  display: none;
  width: 100%;
  margin: 20px 0;
}


@media screen and (min-width: 1200px) {
  .short-p {
    padding: 0 15%;
  }

}  


@media screen and (max-width: 1200px) {
  .info-container div:first-child {
    width: 100%;
  }
  .info-container div:last-child {
    display: none;
   }
  
   .info-container .info-img {
    display: none;
  }

  .info-container .info-img-inline {
    display: block;
  }
  .back-button {
    position: fixed;
    top: 0% !important;
    left: 0% !important;
}

}

#hidden-box {
  display: none; /* Initially hide the box */
}

/* Links */

.contact-links {
  margin-top: 50px;
  font-size: 50px;
}

.contact-links a {
  margin: 10px;
  display: block;
  cursor: pointer;
}

.contact-links a svg {
  fill: var(--text-colour);
  width: 1em;
  max-height: 1em;
  display: inline;
  position: relative;
  margin-right: 0.5em;
  transition: 500ms;
}


.contact-links a {
  color: var(--text-colour);
  transition: 500ms;
  text-decoration: none;
}


.contact-links a:hover span {
  transition: 500ms;
  color: var(--highlight);
}

.contact-links a:hover svg {
  transition: 500ms;
  fill: var(--highlight);
}

/* FOOTER */

.footer {
  
  background-color: var(--third);
  color: var(--text-colour);
  font-size: 14px;
  padding: 20px 0;
  width: 100%;
  margin-top: auto;
  position: relative;
  bottom: 0;
  width: 100%;  
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.footer ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.footer ul li {
  margin: 0 10px;
}

.footer ul li .link {
  color: #fff;
  text-decoration: none;
}

.footer ul li .link:hover {
  text-decoration: underline;
}

/* BUTTON */

.button.float  {
  display: inline-block;
  border-radius: 4px;
  background-color: var(--text-colour);
  border: none;
  color: var(--secondary);
  text-align: center;
  font-size: 21px;
  padding: 10px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.button.float span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button.float span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button.float:hover span {
  padding-right: 25px;
}

.button.float:hover span:after {
  opacity: 1;
  right: 0;
}

.back-button {
  position: fixed;
  top: 2%;
  left: 5%;

}


.back-button button {
  display: inline-block;
  background-color: var(--text-colour);
  border: none;
  color: var(--secondary);
  text-align: center;
  font-size: 21px;
  padding: 10px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.back-button button > span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
  font-style: italic;
}

.back-button button > span:after {
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.back-button button:hover > span {
  padding-right: 25px;
}

.back-button button:hover > span:after {
  opacity: 1;
  right: 0;
}

span.icon {
  margin-right: 5px;
}


/* SLIDER */

.input-container {
  width: 100%; 
}

.input-container.active {
  opacity: 1;
  pointer-events: auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
    transform: translateY(10px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animationSVG {
    position: absolute;
    overflow: hidden !important;
    width: 0;
}

.textarea {
  margin: 20px 0;
  color: var(--primary);
  background-color: var(--text-colour);
  border: none;
  outline: none;
  box-shadow: none;
  padding: 15px;
  font-size: 18px;
  font-family: inherit;
}

.slider-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin: 20px 0;
}

.slider-container label {
  padding: 0 5px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-content: center; */
}

.slider-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  background: var(--text-colour);
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%; 
  background: var(--highlight);
  cursor: pointer;
  transition: background 200ms ease-in-out;

}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--highlight);
  cursor: pointer;
  transition: background 200ms ease-in-out;

}

.slider::-webkit-slider-thumb:hover {
  background: var(--highlight2);
  transition: background 200ms ease-in-out;
}

/* FORM */


.form-navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
}

.form-navigation button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: var(--primary);
  border: none;
  cursor: pointer;
  background-color: var(--text-colour);
  width: 100px;
  margin: 0 10px;
}

.form-navigation button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


/* Carousel */

.img-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  /* filter: blur(1px); */
  z-index: -10;
}

.App-header {
  font-size: 1rem;
  text-align: center;
  font-family: "Oswald", sans-serif;
  padding-bottom: 2rem;
}


.carousel img.active {
  opacity: 1;
  pointer-events: auto;
}

.slide_direction {
  display: flex;
  justify-content: space-between;
}
.left,
.right {
  background-color: #fb666675;
  color: #fff;
  padding: 10px 8px 8px 13px;
  margin: 0 20px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 10px;
  height: 25px;
  width: 25px;
  z-index: 100;
}
.left {
  left: 0;
}
.right {
  right: 0;
}
.carousel-indicator {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}
.dot {
  background-color: #333;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.active {
  background-color: #fa2020;
}

